'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import Events from './events';

// jQuery Easing v1.3 - http://gsgd.co.uk/sandbox/jquery/easing/
$.extend( $.easing, {
  easeOutQuad: function (x, t, b, c, d) {
    return -c *(t/=d)*(t-2) + b;
  }
});

var DesktopHeaderView = Backbone.View.extend({
  initialize: function() {
    var self = this;
    var searchIsOpen = false;

    this.itemActiveClass = "-active";
    this.searchToggleActiveClass = "-active";

    this.$searchToggle = this.$(".DesktopSearch-toggle").attr('role', 'button');
    this.$searchForm = this.$(".DesktopSearch-form");
    this.$searchQuery = this.$(".DesktopSearch-query");

    Events.on("bodyClick", function(event) {
      var $target = $(event.target);

      if ( !$target.closest(".DesktopNavigation-item").length ) {
        self.closeSubMenus();
      }

      if ( !$target.closest(".DesktopSearch").length ) {
        self.closeSearch();
      }
    });
  },

  events: {
    "click .DesktopNavigation-item.-withSubmenu .DesktopNavigation-link": "toggleSubMenu",
    "click .DesktopSearch-toggle": "toggleSearch",
  },

  toggleSearch: function(event) {
    event.preventDefault();

    var self = this;

    if ( this.searchIsOpen ) {
      self.closeSearch();
    } else {
      self.openSearch();
    }
  },

  openSearch: function(event) {
    this.closeSubMenus();
    this.$searchToggle.addClass(this.searchToggleActiveClass);
    this.$searchForm.fadeIn({
      duration: 150,
      easing: "easeOutQuad"
    });
    this.$searchQuery.focus();
    this.searchIsOpen = true;
  },

  closeSearch: function(event) {
    this.$searchToggle.removeClass(this.searchToggleActiveClass);
    this.$searchForm.fadeOut({
      duration: 150,
      easing: "easeOutQuad"
    });
    this.searchIsOpen = false;
  },

  closeSubMenus: function(event) {
    $(".DesktopNavigation-item").removeClass(this.itemActiveClass);
    $(".DesktopNavigation-subMenu").fadeOut({
      duration: 300,
      easing: "easeOutQuad"
    });
  },

  toggleSubMenu: function(event) {
    event.preventDefault();

    var $targetItem = $(event.currentTarget).closest(".DesktopNavigation-item");
    var $targetMenu = $targetItem.find(".DesktopNavigation-subMenu");

    if ($targetItem.hasClass(this.itemActiveClass)) {
      this.closeSubMenus();
    } else {
      this.closeSubMenus();

      // open target item
      $targetItem.addClass(this.itemActiveClass);
      $targetMenu.fadeIn({
        duration: 300,
        easing: "easeOutQuad"
      });
    }
  }

});

$(".DesktopHeader").each( function() {
  var Header = new DesktopHeaderView({ el: this });
});
