//------------------------------------------------------------------------
// Wrap words to prevent orphans
//
// Based on https://github.com/simeydotme/jquery-unorphanize
//------------------------------------------------------------------------
// Basic example:
//
//   <p data-orphans>Lorem ipsum dolor sit amet</p>
//
// Output:
//
//   <p>Lorem ipsum dolor <span class="u-nowrap">sit amet</span></p>
//
//------------------------------------------------------------------------
// Custom word count:
//
//   <p data-orphans="3">Lorem ipsum dolor sit amet</p>
//
// Output:
//
//   <p>Lorem ipsum <span class="u-nowrap">dolor sit amet</span></p>
//
//------------------------------------------------------------------------
// Requires the following CSS rule:
//
//   &-nowrap {
//     @include fs-min-width(320px) {
//       display: inline-block !important;
//       white-space: nowrap !important;
//     }
//   }
//
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import 'jquery-unorphanize';

var Orphans = Backbone.View.extend({
  initialize: function() {
    // Get word count if provided, otherwise default to 2
    this.words = this.$el.data('orphans') || 2;

    // Wrap words
    this.$el.unorphanize({
      words: this.words,
      wrapEl: 'span',
      className: 'u-nowrap'
    });

    // Remove attribute to prevent recursion
    this.$el.removeAttr('data-orphans');
  }
});

$('[data-orphans]').each( function(i) {
  var orphans = new Orphans({ el: this });
});

export default Orphans;
