//------------------------------------------------------------------------
// Section drawer functionality for Publication Collection pages
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import events from './events';

var PublicationDrawer = Backbone.View.extend({
  events: {
    "click .PublicationSection-toggle": "toggle",
  },

  initialize: function() {
    var self = this;
  },

  toggle: function() {
    if (this.$el.find('.PublicationSection-toggle-text')[0].innerText == 'Read More') {
      this.$el.find('.PublicationSection-toggle-text').html('Collapse');
    } else {
      this.$el.find('.PublicationSection-toggle-text').html('Read More');
    };

    this.$el.find('.PublicationSection-toggle-arrow').toggleClass('is-active');
    this.$el.find('.PublicationSection-drawer-content').slideToggle(1000);
    this.$el.find('.PublicationSection-drawer-content-background-shape').toggleClass('is-active');
  },


});

// Initialize
$('.PublicationSection-drawer').each(function() {
  var publicationDrawer = new PublicationDrawer({ el: this });
});

// Return constructor so it can be used by other modules
export default PublicationDrawer;
