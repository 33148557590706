// Global event emitter
// Window resize, scroll, and body click
'use strict';
import $ from 'jquery';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import Backbone from 'backbone';

var Events = _.extend({

  initialize: function() {
    var self = this;
    this.$window = $(window);
    this.$body = $('body');
    this.scrollTimer = null;

    // Save current dimensions to compare on resize
    this.oldWidth = this.getWinWidth();
    this.oldHeight = this.getWinHeight();

    // Add a listener to the window.resize event, pass “this” as the scope
    this.$window.resize( $.proxy( debounce( this.handleResize, 200 ), this ) );

    // Add keyboard listeners
    this.$window.on('keydown', function(evt) {
      self.trigger('keydown', evt);
    });
    this.$window.on('keyup', function(evt) {
      self.trigger('keyup', evt);
    });

    // Listen to scroll event, debounce by 10ms
    // this.$window.scroll( $.proxy( debounce( this.handleScroll, 10 ), this ) );

    // Add listener to the body
    this.$body.on('click', function(evt) {
      self.trigger('bodyClick', evt);
    });

    return this;
  },

  // Disable pointer events on scroll to improve performance
  // http://www.thecssninja.com/javascript/pointer-events-60fps
  //
  // Requires the following CSS:
  //
  // .disable-hover {
  //   pointer-events: none;
  // }
  //---------------------------------
  // disableHover: function() {
  //   var self = this;
  //
  //   clearTimeout( this.scrollTimer );
  //
  //   if ( !this.$body.hasClass('disable-hover') ) {
  //     this.$body.addClass('disable-hover');
  //   }
  //
  //   this.scrollTimer = setTimeout( function() {
  //     self.$body.removeClass('disable-hover');
  //   }, 200 );
  // },

  handleResize: function() {
    var winWidth = this.getWinWidth();
    var winHeight = this.getWinHeight();

    var widthChanged = winWidth !== this.oldWidth;
    var heightChanged = winHeight !== this.oldHeight;

    if ( widthChanged ) {
      // Emit resize event, pass window width
      this.trigger('resize', winWidth);

      // Update window width
      this.oldWidth = winWidth;
    }

    if ( heightChanged ) {
      // Emit vertical resize event, pass window height
      this.trigger('resize-height', winHeight);

      // Update window height
      this.oldHeight = winHeight;
    }
  },

  // handleScroll: function() {
  //   // Emit scroll event, pass current scroll position
  //   this.trigger('scroll', this.$window.scrollTop());
  //
  //   // Disable pointer events while scrolling
  //   this.disableHover();
  // },

  getWinWidth: function() {
    return this.$window.width();
  },

  getWinHeight: function() {
    return this.$window.height();
  }

}, Backbone.Events );

export default Events.initialize();
