function AdminEditLink(config) {
  var self = this;
  function initialize(config) {
    try {
      self.config = config;
      self.el = config.el;
      // console.log(config.el);
    } catch (e) {
      console.warn(
        'A DOM nodes was not passed as config of the AdminEditLink instance.',
        e
      );
      return false;
    }

    self.render();
  }

  this.createAnchor = function(config) {
    try {
      var settings = {
        class: !!config.class ? config.class : 'EditLink',
        href: !!config.href ? config.href : '',
        text: !!config.text ? config.text : 'Edit',
        target: !!config.target ? config.target : '_blank'
      };
    } catch (e) {
      console.warn('Anchor config did not include an href value');
      return false;
    }
    self.el.style.position = 'relative';
    var anchor = document.createElement('a');
    var anchorText = document.createTextNode(settings.text);
    anchor.append(anchorText);
    anchor.setAttribute('href', settings.href);
    anchor.setAttribute('class', settings.class);
    anchor.setAttribute('target', settings.target);
    return anchor;
  };

  this.render = function() {
    try {
      var editableAttribute = this.el.getAttribute('data-editable');
    } catch (e) {
      console.warn(
        'Element does not have a data-editable attribute. Cancelling render'
      );
      return false;
    }

    if (editableAttribute.length) {
      var editableInfo = editableAttribute.split('-');
      if (editableInfo[0] === 'people') {
        var href = '/admin/people/' + editableInfo[1] + '/edit';
      } else {
        var href =
          '/admin/' + editableInfo[0] + 's/' + editableInfo[1] + '/edit';
      }

      var anchor = self.createAnchor({
        class: 'ts-button -primary -save-and-review',
        href: href
      });

      self.el.prepend(anchor);
    }
  };

  initialize(config);
}

module.exports = AdminEditLink;
