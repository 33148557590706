//------------------------------------------------------------------------
// This file contains site-wide JS that will be packaged into a single file
//
// We’re using the sprockets-commoner gem to enable Babel transformations
// https://github.com/Shopify/sprockets-commoner
//------------------------------------------------------------------------
/* globals require */ // JSHint global vars
'use strict';

// Expose jQuery globally (optional)
window.$ = window.jQuery = require('jquery');

// Picture element polyfill
// Requires background-picture.js and ".js-background-image" CSS in _base.scss
// Can remove once support is broad enough http://caniuse.com/#feat=picture
import 'picturefill';

// Include Chosen library
import './lib/jquery.chosen';

// Modules
import './modules/background-picture';
import './modules/custom-select';
import './modules/form-autosubmit';
import './modules/form-validation';
import './modules/jump-links';
import './modules/mailto';
import './modules/megamenu';
import './modules/modals';
import './modules/modal-toggles';
import './modules/multi-select';
import './modules/orphans';
import './modules/poll';
import './modules/publication-drawer';
import './modules/question-carousel';
import './modules/quiz';
import './modules/video-promo';
import './modules/vimeo';
import './modules/youtube';
import AdminEditLink from './modules/admin-edit-link';
import readCookie from './modules/read-cookie';

// Project Modules
import './modules/mobile-header';
import './modules/desktop-header';
import './modules/sessions';
import './modules/content-carousel';

// Twitter widgets
if ($('.twitter-tweet').length) {
  $.getScript('https://platform.twitter.com/widgets.js')
    .done(function(script, textStatus) {})
    .fail(function(jqxhr, settings, exception) {
      console.warn('Twitter widgets could not be loaded');
    });
}

// Admin Edit Links
if (readCookie('signed_in') === '1') {
  var editableElements = document.querySelectorAll('[data-editable]');
  [].forEach.call(editableElements, function(el) {
    new AdminEditLink({
      el: el
    });
  });
}
