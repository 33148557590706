//------------------------------------------------------------------------
// Modal window toggle buttons (see modals.js for modal window content)
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import events from './events';

var ModalToggle = Backbone.View.extend({
  events: {
    'click': 'toggle'
  },

  initialize: function() {
    var self = this;

    // Get target modal name
    this.name = this.$el.data('modal-toggle');

    // Check if modal exists
    this.$modal = $('#' + this.name);

    if ( !this.$modal.length ) {
      console.warn('Can’t find modal window “' + this.name + '” for toggle', this.$el);
      return false;
    }

    this.setup();
  },

  setup: function() {
    // Add ARIA attributes to toggle
    // Don’t use “aria-controls” http://www.heydonworks.com/article/aria-controls-is-poop
    this.$el.attr({
      'aria-expanded': 'false',
      'role': 'button'
    });
  },

  toggle: function(evt) {
    // If replaceState() is supported, prevent default to avoid adding an entry to the history
    if ( history.replaceState ) {
      evt.preventDefault();
    }

    // Trigger modal toggle event for modals.js
    events.trigger('modal-toggle', evt, this.name);
  }
});

// Initialize
$('[data-modal-toggle]').each(function() {
  new ModalToggle({ el: this });
});

// Return constructor so it can be used by other modules
export default ModalToggle;
