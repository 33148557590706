//------------------------------------------------------------------------
// Basic form validation
//
// Usage Info:
// • Add “js-validate” to <form>
// • Add appropriate data-validate value to each input
//   - data-validate="email"
//   - data-validate="phone"
//   - data-validate="number"
//   - data-validate="zip"
//   - data-validate="notempty"
// • Add error message markup inside of <label>
//     <span class="is-hidden" data-validate="error" role="alert">Please enter a valid email address</span>
// • Add `data-validate="submit"` to submit button
//     <button type="submit" data-validate="submit">Submit</button>
// • Required fields should use `aria-required="true"` when possible, but `required` and `data-validate-required` will also work
//  • For groups of checkboxes, wrap in <fieldset data-validate-required> and add appropriate `data-validate-group` value (see examples below)
//    - data-validate-group="min-1"
//    - data-validate-group="max-2"
//    - data-validate-group="notempty"
//  • For groups of radios, wrap in <fieldset role="radiogroup" aria-required="true"> and add appropriate `data-validate-group` value (see examples above)
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import smoothScroll from './smoothScroll';

var ValidateForm = Backbone.View.extend({
  events: {
    'blur [data-validate]': 'validate',// check for errors on blur
    'change [data-validate]': 'validate',// for selects, radios, and checkboxes
    'change [data-validate-group] input': 'validateGroup',// for selects, radios, and checkboxes
    'input input[data-validate]': 'clearError',// hide error while typing
    'input textarea[data-validate]': 'clearError',// hide error while typing
    'keypress [data-validate="number"]': 'numberEvent',// prevent non-number characters
    'submit': 'submitHandler'// validate prior to submitting form
  },

  initialize: function() {
    var self = this;

    // Add the “novalidate” attribute to form to disable default browser error messages if using “required” attribute
    // Note: `prop('novalidate', true)` doesn't work for some reason ¯\_(ツ)_/¯
    this.$el.attr('novalidate', 'novalidate');

    // Find error messages
    this.$errorMsg = this.$el.find('[data-validate="error"]');

    // Submit button
    this.$submit = this.$el.find('[data-validate="submit"]');
    this.shouldScroll = !this.$submit.is('[data-no-jump]');
    this.submitText = this.$submit.text();// initial text
    this.submittedText = this.$submit.data('validate-success') || false;// text to show on submit

    // Elements that require validation, set to `aria-invalid="true"` on load
    this.$validateEls = this.$el.find('[data-validate]').not(this.$submit).not(this.$errorMsg);

    // Groups of checkboxes/radios that require validation
    this.$groups = this.$el.find('[data-validate-group]');

    // Store previously focused checkbox/radio group, validate when focus changes
    this.$prevGroup = false;

    // Validating every possible email is a fool’s errand and not necessary.
    // Instead, just check for at least one “@” and “.”
    // https://davidcel.is/posts/stop-validating-email-addresses-with-regex/
    this.emailRegex =  /^.+@.+\..+$/;

    // If more validation is desired, we can check for only one “@”
    // Based on https://github.com/plataformatec/devise/blob/593ae41f9dac165a404b05cd3abd959245c64908/lib/devise.rb#L109-L113
    // this.emailRegex =  /^[^@\s]+@([^@\s]+\.)+[^@\s]+$/;

    // Numeric value test (allows single decimal)
    this.numericRegex = /^((\d+)|(\.\d+)|(\d+\.\d+))%?$/;

    // Phone number test (very loose, allows everything except line breaks)
    this.phoneRegex = /^.+$/;

    // Postal code RegEx, allows from 2–12 letters, numbers, spaces, or dashes
    this.zipRegex = /^[\w\d\- ]{2,12}$/;

    // Using the back button in Firefox and Safari displays a cached page with the submit button still disabled, so we have to manually reenable it.
    // https://bugzilla.mozilla.org/show_bug.cgi?id=443289
    // http://stackoverflow.com/a/13123626/
    $(window).on('pageshow', function(evt) {
      if ( evt.originalEvent.persisted ) {
        self.$submit.prop('disabled', false).text(self.submitText);
      }
    });
  },

  isEmail: function(str) {
    return this.emailRegex.test(str);
  },

  isNumeric: function(str) {
    return this.numericRegex.test(str);
  },

  isPhone: function(str) {
    return this.phoneRegex.test(str);
  },

  isZip: function(str) {
    return this.zipRegex.test(str);
  },

  isEmpty: function($el) {
    // Check if checkbox or radio button
    if ( $el.is('[type="checkbox"]') || $el.is('[type="radio"]') ) {
      return !$el.is(':checked');
    }
    // For all other elements, get the value
    else {
      return !$el.val();
    }
  },

  isRequired: function($el) {
    // We can’t apply the “required” attribute or “aria-required="true"” on checkbox inputs, or groups of them, so we have to use the  custom “data-validate-required” attribute
    // https://github.com/GoogleChrome/accessibility-developer-tools/issues/283
    return $el.is('[aria-required="true"]') || $el.is('[required]') || $el.is('[data-validate-required]');
  },

  numberEvent: function(evt) {
    // Get typed character
    var key = String.fromCharCode(evt.which);
    var allowedChars = /[,.0-9%]/;

    // Don't allow non-number characters to be entered
    if ( !allowedChars.test(key) ) {
      evt.preventDefault();
    }
  },

  // Get label element whose “for” attribute matches current element’s “id” attribute
  getErrorMsgParent: function($el) {
    // If element has an id, look for an associated label
    if ( !!$el.attr('id') ) {
      return this.$el.find('[for="' + $el.attr('id') + '"]');
    }
    // Groups don’t have ids and associated labels, so get error message parent
    else {
      var $error = $el.find('[data-validate="error"]');
      return $error.length ? $error.parent() : false;
    }
  },

  // Get error message element associated with form element
  getErrorMsg: function($el) {
    return this.getErrorMsgParent($el).find('[data-validate="error"]');
  },

  // Show an element’s error message and update its “aria-invalid” state
  showErrorMsg: function($el) {
    this.getErrorMsg($el).removeClass('is-hidden');
    $el.addClass('is-invalid').attr('aria-invalid', 'true');
  },

  // Hide an element’s error message and update its “aria-invalid” state
  hideErrorMsg: function($el) {
    this.getErrorMsg($el).addClass('is-hidden');
    $el.removeClass('is-invalid').attr('aria-invalid', 'false');
  },

  // Show group error
  showGroupErroMsg: function($el) {
    $el.attr('aria-invalid', 'true').find('[data-validate="error"]').removeClass('is-hidden');
  },

  // Clear group error
  hideGroupErrorMsg: function($el) {
    $el.attr('aria-invalid', 'false').find('[data-validate="error"]').addClass('is-hidden');
  },

  // Toggle error message
  toggleErrorMsg: function(isValid, $el) {
    if ( isValid ) {
      this.hideErrorMsg($el);
    }
    else {
      this.showErrorMsg($el);
    }
  },

  // Event handler to hide error messages while typing in text inputs
  clearError: function(evt) {
    var $target = $(evt.target);
    this.hideErrorMsg($target);
  },

  isFormValid: function() {
    // Check for required and invalid fields
    var $invalidEls = this.$validateEls.add(this.$groups).filter('[aria-invalid="true"]');

    return !$invalidEls.length;
  },

  // Test if element is valid and toggle error msg
  validateEl: function($el) {
    var type = $el.data('validate');
    var val = $el.val();

    // Email
    if ( type === 'email' ) {
      this.toggleErrorMsg(this.isEmail(val), $el);
    }

    // Numbers (allows single decimal and/or % sign)
    else if ( type === 'number' ) {
      // Strip commas
      val = val.replace(/,/g, '');
      $el.val(val);
      this.toggleErrorMsg(this.isNumeric(val), $el);
    }

    // Phone number (allows everything except line breaks)
    else if ( type === 'phone' ) {
      this.toggleErrorMsg(this.isPhone(val), $el);
    }

    // ZIP code (from 2–12 letters, numbers, dashes, or spaces)
    else if ( type === 'zip' ) {
      this.toggleErrorMsg(this.isZip(val), $el);
    }

    // Not empty
    else if ( type === 'notempty' ) {
      // TODO: Allow on fieldsets containing radio buttons

      this.toggleErrorMsg(!this.isEmpty($el), $el);
    }
  },

  // Event handler to validate elements on blur or change
  validate: function(evt) {
    var $target = $(evt.target);

    // Exclude blank optional fields
    if ( this.isRequired($target) || !this.isEmpty($target) ) {
      this.validateEl( $(evt.target) );
    }

    // Reset current group (the elements that trigger this function aren’t part of a group)
    this.$currentGroup = false;
  },

  // Validate all single elements
  validateAll: function() {
    var self = this;

    this.$validateEls.each( function() {
      var $this = $(this);
      // Exclude blank optional fields
      if ( self.isRequired($this) || !self.isEmpty($this) ) {
        self.validateEl($this);
      }
    });
  },

  // Validate group when focus leaves (can’t detect blur on fieldsets)
  validateGroupEl: function($el) {
    var condition = $el.data('validate-group');
    var isExact = typeof condition === 'number';
    var selectedEls = $el.find('input:checked').length;

    // If checked inputs match required number, clear error
    if ( isExact && condition === selectedEls ) {
      // console.log('matches exact number');
      this.hideGroupErrorMsg($el);
    }
    // Check condition string
    else if ( !isExact ) {
      // “notempty”
      if ( condition === 'notempty' ) {
        if ( selectedEls === 0 ) {
          this.showGroupErroMsg($el)
        }
        else {
          // console.log('valid notempty');
          this.hideGroupErrorMsg($el);
        }
      }
      // min/max
      else {
        condition = condition.split('-');

        if ( condition.length !== 2 ) {
          console.warn('data-validate-group value is malformed: ' + condition);
        }
        else {
          var type = condition[0];
          var value = parseInt(condition[1], 10);

          if ( type !== 'min' &&  type !== 'max' ) {
            console.warn('data-validate-group value is malformed: ' + condition);
          }
          // Min
          else if ( type === "min" && selectedEls >= value ) {
            // console.log('valid min', condition, value);
            this.hideGroupErrorMsg($el);
          }
          // Max
          else if ( type === "max" && selectedEls <= value ) {
            // console.log('valid max', condition, value);
            this.hideGroupErrorMsg($el);
          }
          // Show error
          else {
            this.showGroupErroMsg($el)
          }
        }
      }
    }
    // Show error
    else {
      this.showGroupErroMsg($el)
    }
  },

  // Event handler to validate group on change
  validateGroup: function(evt) {
    var $target = $(evt.target);
    var $currentGroup = $target.closest('[data-validate-group]');

    // Clear error on current group
    this.hideGroupErrorMsg($currentGroup);

    // If no previous group, set it to current group
    if ( !this.$prevGroup ) {
      this.$prevGroup = $currentGroup;
    }
    // If group has changed, validate the previous group
    else if ( !!this.$prevGroup && !$currentGroup.is(this.$prevGroup) ) {
      this.validateGroupEl( this.$prevGroup );

      // Update previous group
      this.$prevGroup = $currentGroup;
    }
  },

  // Validate all groups
  validateAllGroups: function() {
    var self = this;

    this.$groups.each( function() {
      var $this = $(this);

      // Validate required groups and those with selected items
      // (non-required groups without any selected items will be ignored)
      if ( self.isRequired($this) || $this.find(':checked').length ) {
        self.validateGroupEl($this);
      }
    });
  },

  // Don't allow submit if validation errors are present
  submitHandler: function(evt) {
    var self = this;

    // Validate all single elements
    this.validateAll();

    // Validate all groups
    this.validateAllGroups();

    // If form is invalid, scroll to first error message
    if ( !this.isFormValid() ) {
      // Prevent form submission
      evt.preventDefault();

      // Add invalid class
      this.$el.addClass('is-invalid');

      // Find first error message
      var $errMsg = this.getErrorMsgParent( $('[aria-invalid="true"]').first() );

      // Scroll to first error
      if ( this.shouldScroll ) {
        smoothScroll($errMsg, 1000, true);
      }
    }
    // Form is valid
    else {
      // Remove invalid class
      this.$el.removeClass('is-invalid');

      // Disable submit button to prevent multiple submissions
      this.$submit.prop('disabled', true);

      // Update submit button text if provided
      if ( !!this.submittedText ) {
        this.$submit.text( this.submittedText );
      }

      // Optional: Trigger “form-valid” event for other modules
      // Backbone.trigger('form-valid', $(evt.target));
    }
  }
});

$('form.js-validate').each(function() {
  new ValidateForm({el: this});
});
