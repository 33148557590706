//------------------------------------------------------------------------
// Quiz functionality
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';

var Quiz = Backbone.View.extend({
  events: {
    'click .Question-reveal': 'showAnswer',
    'click .Question-next': 'nextQuestion'
  },

  initialize: function() {
    this.currentQuestion = 0;

    this.$panels = this.$el.find('.Question-panel');
    this.$panels.first().attr('aria-hidden', 'false');
    this.$panels.slice(1).attr('aria-hidden', 'true');
  },

  showAnswer: function(evt) {
    var $target = $(evt.target).addClass('is-hidden');
    var $answer = $target.next('.Question-answer').addClass('is-active');
  },

  nextQuestion: function() {
    // Get currently active question
    var $current = this.$panels.eq(this.currentQuestion);

    // Check if on last question
    if ( this.currentQuestion === (this.$panels.length - 1) ) {
      this.currentQuestion = 0;
    }
    else {
      this.currentQuestion += 1;
    }

    // Get next question
    var $next = this.$panels.eq(this.currentQuestion);

    // Hide current and show next question
    $current.attr('aria-hidden', 'true');
    $next.attr('aria-hidden', 'false');
  }

});

// Initialize
$('.Question').each(function() {
  var quiz = new Quiz({ el: this });
});
