//------------------------------------------------------------------------
// Megamenu functionality for Publication Collection pages
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import events from './events';

var Megamenu = Backbone.View.extend({
  events: {
    "click .Megamenu-close": "toggle",
    "click .Megamenu-open": "toggle",
    "change .Megamenu-form-dropdown": "changeTab",
  },

  initialize: function() {
    var self = this;
    var section = '#' + ($('.Megamenu-form-dropdown')[0].value).toString();
    $(section).addClass('open-section');
  },

  toggle: function() {
    $('.Megamenu-content').slideToggle();
    // $('.Megamenu-content').toggleClass('is-active');
    $('.Megamenu-open').toggleClass('is-active');
    $('.Megamenu-close').toggleClass('is-active');
  },

  changeTab: function() {
    var section = '#' + ($('.Megamenu-form-dropdown')[0].value).toString();
    $('.open-section').removeClass('open-section');
    $(section).addClass('open-section');
  },

});

// Initialize
$('.Megamenu').each(function() {
  var megamenu = new Megamenu({ el: this });
});

// Return constructor so it can be used by other modules
export default Megamenu;
