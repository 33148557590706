//------------------------------------------------------------------------
// Disable scrolling (used by modals)
//
// Requires these styles:
//
// /*html*/.js-no-scroll {
//   height: 100% !important;
//
//   body {
//     height: 100% !important;
//     overflow: hidden !important;
//     position: fixed !important;
//     width: 100% !important;
//   }
// }
//
//------------------------------------------------------------------------
// We have to set "body { position: fixed; }" to prevent iOS from scrolling when modal is open,
// which normally causes the browser to scroll back up to the top. To prevent this, we must set
// inline styles on the body based on the curretn scroll position.
export default {
  _docClasses: document.documentElement.className,
  _scrollPos: 0,

  _saveScrollPos: function() {
    this._scrollPos = window.pageYOffset || document.documentElement.scrollTop;
  },

  // Disable scrolling
  disable: function() {
    this._saveScrollPos();

    // Add class to prevent page scrolling (sets fixed position on body)
    document.documentElement.className = this._docClasses + ' js-no-scroll';

    // Add inline styles if not already at top of page
    if ( this._scrollPos > 0 ) {
      document.body.style.height = 'calc(100% + ' + this._scrollPos + 'px)';
      document.body.style.top = -this._scrollPos + 'px';
    }
  },

  // Enable scrolling
  enable: function() {
    // Remove js-no-scroll class
    document.documentElement.className = this._docClasses.replace('js-no-scroll', '');

    // Remove body styles, reset scrolling
    if ( this._scrollPos > 0 ) {
      document.body.style.height = '';
      document.body.style.top = '';
      window.scrollTo(0, this._scrollPos);
    }
  }
};
