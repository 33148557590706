//------------------------------------------------------------------------
// Autosubmit form data on checkbox or radio change
//
// NOTE: Avoid using this unless absolutely necessary due to accessibility issues.
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';

// Find forms that should auto-submit
$("form[data-autosubmit]").each( function() {
  var $this = $(this);
  var $inputs = $this.find('input[type="checkbox"], input[type="radio"], select');

  if ( $inputs.length ) {
    // Submit form when inputs are changed
    $inputs.on('change', function() {
      $this.submit();
    });
  }
});
