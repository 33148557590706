// mailto.js

// Looks for all elements with a "data-email" attribute and converts them to mailto links, using the value of that attribute as the email address. Inner HTML is preserved and any occurrences of "[at]" are converted to "@".

// Exmaple: <span data-email="hello[at]threespot.com">Contact Us</span>
// Becomes: <a href="mailto:hello@threespot.com">Contact Us</a>

// Works with inner HTML
// Exmaple: <span data-email="hello[at]threespot.com"><b>hello[at]threespot.com</b></span>
// Becomes: <a href="mailto:hello@threespot.com"><b>hello@threespot.com</b></a>

'use strict';
import $ from 'jquery';

// Find all instances of [data-email] and generate mailto links from obfuscated code
$('[data-email]').each( function() {
  var $el = $(this);

  // Generate mailto href
  var href = 'mailto:' + $el.attr('data-email').replace('[at]', '@');

  // Replace [at] with @ in link text
  var linkHtml = $el.html();
  if ( linkHtml.indexOf('[at]') > 0 ) {
    linkHtml = linkHtml.replace('[at]', '@');
    $el.html(linkHtml);
  }

  // Add mailto href to link tags
  if ( $el.is('a') ) {
    $el.attr('href', href);
  }
  // Create link tag, remove original element
  else {
    var $link = $('<a/>', {
      'href': href,
      'html': linkHtml
    });

    // Add classes to generated link if present
    var classNames = $el.attr('class');
    if ( !!classNames ) {
      $link.attr('class', classNames);
    }

    // Insert link and remove original element
    $el.before($link).remove();
  }
});
