// YouTube video players
// https://developers.google.com/youtube/iframe_api_reference
//
// Requires YouTube Data API key to get video title
// https://developers.google.com/youtube/v3/getting-started
//
// This module will dynamically load the JS API, build the players, and attach event listeners for GTM.
//
// <div data-youtube="FuYW3GdPhsc"><a href="https://www.youtube.com/watch?v=FuYW3GdPhsc">Watch video on YouTube</a></div>
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import once from 'lodash/once';
import events from './events';
const publicKey = '<%= ENV.fetch("YOUTUBE_PUBLIC_KEY") %>';

// Video constructor
var YouTubePlayer = Backbone.View.extend({
  initialize: function() {
    var self = this;
    this.url = window.location.href;

    // Create unique ID for each video
    this.uniqueID = 'YT' + this.cid;

    // Get playerVars from optional data attributes if present
    this.collectVars();

    // Check if iframe embed
    if ( this.el.nodeName.toLowerCase() === 'iframe' ) {
      // Add unique id
      this.$el.attr('id', this.uniqueID);

      // Get vars from iframe src attribute
      this.iframeVars();
    }
    // Embed using data attributes
    else {
      // Get YouTube video ID
      this.videoID = this.$el.attr('data-youtube');

      // Create placeholder element that iframe will replace
      $('<div>', {
        id: this.uniqueID
      }).appendTo(this.$el);

      if ( !this.videoID ) {
        console.warn('Missing YouTube ID');
      }

      // Store the video name for access later
      this.videoTitle = this.$el.attr('data-youtube-title') || '';

      // Lookup video title if not provided
      if ( !this.videoTitle.length ) {
        this.getVideoData( function( data ) {
          if ( 'items' in data && data.items.length) {
            var video = data.items[0];
            self.videoTitle = $.trim( video.snippet.title );
            // self.description = video.snippet.description || '';
          }
          else {
            console.warn('Can’t get YouTube data for video ID ' + self.videoID);
          }
        });
      }
    }

    // Lazy load functionality
    this.shouldLazyload = this.$el.hasClass('js-lazyload');
    this.hasLoaded = false;

    if ( this.shouldLazyload && !this.hasLoaded ) {
      events.on('load-video', function(id, autoplay) {
        if ( self.videoID === id ) {
          // console.log('heard load-video', id, autoplay);
          self.buildPlayer(autoplay);
        }
      });
    }
    // Build if lazy load not enabled
    else if ( !this.shouldLazyload ) {
      this.buildPlayer();
    }
  },

  // Get video metadata using YouTube gdata API
  // https://www.googleapis.com/youtube/v3/videos?id=jZ4cur1kgog
  getVideoData: function( callback ) {
    var self = this;
    var jqxhr = $.ajax({
      type: 'GET',
      url: 'https://www.googleapis.com/youtube/v3/videos?id=' + self.videoID + '&key=' + publicKey + '&part=snippet,contentDetails',
      dataType: 'jsonp',
      crossDomain: true,
      success: function( data ) {
        // console.log('YT get data', data);
        callback( data );
      },
      error: function() {
        this.videoTitle = 'Title not found';
        return false;
      }
    });
  },

  // Collect player vars from data attributes (if present)
  // https://developers.google.com/youtube/player_parameters?playerVersion=HTML5#Parameters
  collectVars: function() {
    this.playerVars = {};
    this.playerVars.autohide = this.$el.attr('data-youtube-autohide') || 1;
    this.playerVars.autoplay = this.$el.attr('data-youtube-autoplay') || 0;
    // Note: controls=2 does not work when enablejsapi=1
    this.playerVars.controls = this.$el.attr('data-youtube-controls') || 2;
    this.playerVars.iv_load_policy = this.$el.attr('data-youtube-iv_load_policy') || 3;
    this.playerVars.modestbranding = this.$el.attr('data-youtube-modestbranding') || 1;
    this.playerVars.showinfo = this.$el.attr('data-youtube-showinfo') || 0;
    this.playerVars.rel = this.$el.attr('data-youtube-rel') || 0;
    this.playerVars.wmode = 'opaque';
    // console.log('this.playerVars', this.playerVars);
  },

  iframeVars: function() {
    var self = this;

    // Get video ID from iframe URL and check for "rel" param
    var iframeSrc = this.$el.attr('src');
    var pattern = /(?:youtube.*\/embed\/)([-0-9A-z]*)(\S*(?:rel\=)([0-9]))?/g;
    var matches = pattern.exec( iframeSrc );

    if ( !!matches && matches.length > 1 ) {
      this.videoID = matches[1];
      // Set related videos param
      this.playerVars.rel = matches[3] || 1;
    }

    // Get video title
    this.getVideoData( function( data ) {
      self.videoTitle = data.items[0].snippet.title;
    });

    // Add enablejsapi=1 to iframe src to allow API to work
    if ( iframeSrc.indexOf('enablejsapi') === -1 || iframeSrc.indexOf('wmode') === -1 ) {
      var separator = iframeSrc.indexOf('?') > 0 ? '&' : '?';
      iframeSrc += separator + 'enablejsapi=1&' + $.param( this.playerVars );
      this.$el.attr( 'src', iframeSrc );
    }
  },

  buildPlayer: function(autoplay) {
    var self = this;

    if ( typeof autoplay !== 'undefined' ) {
      this.playerVars.autoplay = true;
    }

    // Replace the placeholder element with the iframe
    this.player = new YT.Player( this.uniqueID, {
      videoId: self.videoID,
      playerVars: self.playerVars,
      events: {
        'onReady': function() {
          // console.log('YT ready', self.uniqueID);
          self.videoReady();
        },
        'onStateChange': function() {
          // console.log('YT change', self.uniqueID);
          self.onPlayerStateChange();
        }
      }
    });
  },

  videoReady: function() {
    this.hasLoaded = true;

    // Add init class to wrapper to hide the fallback link
    this.$el.addClass('js-init');

    // Save video duration
    this.duration = this.player.getDuration();

    // Ensure analytics functions only run once
    // http://underscorejs.org/#once
    this.analyticsPlay = once(this.analyticsPlay);
    this.analytics10s = once(this.analytics10s);
    this.analytics50 = once(this.analytics50);
    this.analyticsEnd = once(this.analyticsEnd);

    // Emit video event if inside a modal window
    var $modal = this.$el.closest('[data-modal]');

    if ( $modal.length ) {
      events.trigger('modal-video-ready', $modal, this.player);
    }
  },

  // make start/end analytics calls, monitor video while playing
  onPlayerStateChange: function() {
    var self = this;
    this.state = this.player.getPlayerState();

    // Playing
    if ( this.state === YT.PlayerState.PLAYING ) {
      this.analyticsPlay();

      // Track elapsed time
      this.timer = setInterval(function() {
        this.monitorVideo();
      }.bind(this), 1000);
    }
    // Finished
    else if ( this.state === YT.PlayerState.ENDED ) {
      this.analyticsEnd();
      clearInterval( this.timer );
    }
    else {
      clearInterval( this.timer );
    }
  },

  // Track video progress
  monitorVideo: function() {
    var time = this.player.getCurrentTime();

    // Viewed 10 seconds
    if ( time > 10 ) {
      this.analytics10s();
    }

    // Viewed 50%
    if ( time / this.duration >= 0.5 ) {
      this.analytics50();
    }
  },

  // Analytics Functions (replace logs with correct GA calls - video name stored in 'this.videoTitle')
  analyticsPlay: function() {
    this.gtmVideoAction('play');
  },

  analytics10s: function() {
    this.gtmVideoAction('watched 10s');
  },

  analytics50: function() {
    this.gtmVideoAction('watched 50%');
  },

  analyticsEnd: function() {
    this.gtmVideoAction('finished');
  },

  gtmVideoAction: function(action) {
    // console.log({
    //   'event': 'customVideoEvent',
    //   'videoTitle': this.videoTitle,
    //   'videoAction': action
    // });

    // Use try/catch to be safe
    try {
      window.dataLayer.push({
        'event': 'customVideoEvent',
        'videoTitle': this.videoTitle,
        'videoAction': action
      });
    } catch(e) {
      console.warn('GTM: Unable to push YouTube data to dataLayer');
    }
  }
});

// Find videos
var $videos = $("[data-youtube], iframe[src*='youtube.com'], iframe[src*='youtube-nocookie.com']");

// Load JS API and create video players if present
if ( $videos.length ) {

  // Build video players
  var buildPlayers = function() {
    $videos.each( function() {
      var video = new YouTubePlayer({el: this});
    });
  };

  // Check if YouTube script has already run
  // https://developers.google.com/youtube/iframe_api_reference
  if (typeof window.onYouTubeIframeAPIReady == 'undefined') {
    // Set custom “onYouTubeIframeAPIReady” callback that will fire once the script has downloaded
    window.onYouTubeIframeAPIReady = function() {
      buildPlayers();
    };

    // Get YouTube JS API script
    $.getScript('https://www.youtube.com/iframe_api')
      .fail(function( jqxhr, settings, exception ) {
        console.warn('YouTube Iframe Player API could not be loaded');
      });
  }
  // If YouTube script has previously run, we can build players immediately
  else {
    buildPlayers();
  }
}