'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import slick from 'slick-carousel';

var ContentCarousel = Backbone.View.extend({
  initialize: function() {
    this.$slides = this.$('.ContentCarousel-slides');
    console.log(this.$slides);

    this.$slides.slick({
      adaptiveHeight: true,
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: `
        <button class="ContentCarousel-button ContentCarousel-button--next">
          <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg>
        </button>
      `,
      prevArrow: `
        <button class="ContentCarousel-button ContentCarousel-button--prev">
          <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1203 544q0 13-10 23L800 960l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L631 983q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>
        </button>
      `
    });
  }
});

$('.ContentCarousel').each(function() {
  new ContentCarousel({ el: this });
});
