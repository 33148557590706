//------------------------------------------------------------------------
// Smooth scroll jump links
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import smoothScroll from './smoothScroll';

var JumpLinks = Backbone.View.extend({
  el: 'body',

  events: {
    'click a[href^="#"]': 'clickHandler'
  },

  initialize: function() {
    var self = this;
    this.scrollSpeed = 800;// scroll speed in milliseconds
    this.$window = $(window);

    // Force browser to scroll to top of target element when using the back/forward buttons
    this.$window.on('hashchange', function(evt) {
      // Get current hash
      var hash = evt.originalEvent.newURL.split('#')[1];

      // Scroll to top if no hash
      if ( !hash ) {
        self.$window.scrollTop(0);
      }
      // Scroll to element with mathcing ID if it exists
      else {
        var $target = $('#' + hash);

        if ( $target.length ) {
          self.$window.scrollTop( $target.offset().top );
        }
      }
    });
  },

  clickHandler: function(evt) {
    var $target = $(evt.currentTarget);
    var shouldJump = typeof $target.attr('data-no-jump') === 'undefined';
    var shouldClearHash = typeof $target.attr('data-clear-hash') !== 'undefined';

    // Check if link should open in a new window
    var opensNewWindow = $target.is('[target="_blank"]') || evt.which > 1 || evt.metaKey || evt.ctrlKey || evt.shiftKey || evt.altKey;

    // Ignore links that open in a new window or that have a “data-no-jump” attribute
    if ( !opensNewWindow && shouldJump ) {

      // Prevent default to stop page jump
      evt.preventDefault();

      // Find element to scroll to (assumes using “id” attribute, not “name”)
      var selector = $target.attr('href');

      // Check if href contains only “#”
      if ( selector === '#' ) {
        // Scroll to top
        smoothScroll(0, this.scrollSpeed, true);

        // Remove hash only if one is present
        if ( window.location.hash.length > 0 ) {
          if ( history.pushState ) {
            history.pushState(null, '', window.location.pathname);
          }
          else {
            window.location.hash = '';
          }
        }
      }
      // Check if target element exists
      else {
        var $el = $(selector);

        if ( $el.length ) {
          // Smooth scroll
          smoothScroll($el, this.scrollSpeed, true);

          // Shift focus to target element
          // http://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/
          $el.attr('tabindex', '-1').on('blur focusout', function() {
            $(this).removeAttr('tabindex');
          }).focus();// focus on the content container

          // Update URL hash after smooth scroll completes
          // NOTE: Using pushState doesn’t trigger :target styles so only use it when clearing the hash
          if ( shouldClearHash ) {
            if ( history.pushState ) {
              history.pushState(null, '', window.location.pathname);
            }
            else {
              window.location.hash = '';
            }
          }
          // Add hash
          else {
            window.location.hash = selector;
          }
        }
        else {
          console.warn('There is no element matching “' + selector + '” to scroll to');
        }
      }
    }
  }

});

var jumpLinks = new JumpLinks();
