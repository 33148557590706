//------------------------------------------------------------------------
// Quiz functionality
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';

var Quiz = Backbone.View.extend({
  events: {
    'click .Quiz-action-button': 'buttonHandler',
    'click .Quiz-action-button--reset': 'reset',
    'submit .Quiz-action-form': 'submitHandler'
  },

  initialize: function() {
    this.$eligible = $('#eligible');
    this.$form = this.$el.find('.Quiz-action-form');
    this.$fee = this.$el.find('.Quiz-fee-amount');
    this.defaultFee = this.$fee.text();
    this.$submitButton = this.$el.find('[data-validate="submit"]');
  },

  showPanel: function($panel) {
    // Check if panel exists
    if ( $panel.length ) {
      // Hide old item
      this.$el.find('[aria-hidden="false"]').attr('aria-hidden', 'true').removeClass('is-active');

      // Show target item
      $panel.attr('aria-hidden', 'false').addClass('is-active');
    }
  },

  // Add commas to dollar amounts
  // Source: http://stackoverflow.com/a/2901298
  formatDollars: function(val) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  buttonHandler: function(evt) {
    var $target = $(evt.target);
    var href = $target.attr('href');

    // Make sure this is a jump link
    if ( href.indexOf('#') === 0 ) {
      evt.preventDefault();

      var targetId = $target.attr('href').substring(1);
      var $targetItem = $('#' + targetId);

      // Update panels
      this.showPanel( $targetItem );
    }
  },

  reset: function() {
    this.$fee.text(this.defaultFee);
  },

  submitHandler: function(evt) {
    // Prevent form from submitting since we don’t want to force a page refresh
    evt.preventDefault();

    // Check if form is valid
    if ( !this.$form.hasClass('is-invalid') ) {

      // Re-enable submit button (disabled by form validation script to prevent multiple sunbmissions)
      // in case users go back to this panel again.
      this.$submitButton.prop('disabled', false);

      // Get select box value
      var fee = parseInt($(evt.target).find('select').val(), 10);

      // Check to make sure value is a number
      if ( !isNaN(fee) ) {
        // Update fee text
        this.$fee.text( this.formatDollars(fee) );

        // Go to eligibility panel
        this.showPanel( this.$eligible );
      }
    }
  }

});

// Initialize
$('[data-quiz]').each(function() {
  var quiz = new Quiz({ el: this });
});
