//------------------------------------------------------------------------
// Smooth scrolling
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import ua from './user-agent';

// Add custom jQuery easing
// https://github.com/gdsmith/jquery.easing
$.extend($.easing, {
  easeOutExpo: function(x, t, b, c, d) {
    return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
  }
});

export default function( target, speed, isSmooth, callback ) {
  var hasTargetEl = false;
  var harTargetPos = false;
  var targetPos = 0;
  var $window = $(window);
  var topPos = $window.scrollTop();
  var offset = 0;
  var androidVersion = ua.isAndroid();
  var isAndroid = !!androidVersion;
  var isOldAndroid = isAndroid && androidVersion <= 2;

  // If a number was provided, use that as scroll position
  if ( typeof target == 'number' ) {
    harTargetPos = true;
    targetPos = target;
  }
  // If a jQuery element was passed, get its top offset
  else if ( target instanceof $ && target.length ) {
    hasTargetEl = true;
    targetPos = Math.floor( target.offset().top );
  }
  else {
    console.warn('Cannot find element to scroll to');
    return false;
  }

  // Default to no animation
  if ( typeof isSmooth == 'undefined' ) {
    isSmooth = true;
  }

  // Don't scroll if already at target position
  if ( topPos == targetPos ) {
    // Shift focus to target element
    if ( hasTargetEl ) {
      // http://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/
      target.attr('tabindex', '-1').on('blur focusout', function() {
        $(this).removeAttr('tabindex');
      }).focus();// focus on the content container
    }

    return false;
  }

  // Smooth scroll if target is an element and not old Android
  if ( (hasTargetEl || harTargetPos) && isSmooth && !isOldAndroid ) {

    // Animate both html and body for cross-browser compatibility
    $('html, body').stop(true,true).animate({ scrollTop: targetPos - offset },
      (typeof speed == 'undefined' ? 800 : speed), 'easeOutExpo',
      // This animation complete will run twice since we're animating 2 elements
      function() {

        // Don't run focus on the same element twice
        if ( hasTargetEl && target.attr('tabindex') !== '-1' ) {
          // Shift focus to target element
          // http://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/
          target.attr('tabindex', '-1').on('blur focusout', function() {
            $(this).removeAttr('tabindex');
          }).focus();// focus on the content container
        }

        // Remove hash if scroll to top (still shows “#” in URL)
        if ( $window.scrollTop() <= 1 ) {
          window.location.hash = '';
        }
        // Add hash back to URL after scroll animation complete
        else if ( hasTargetEl && !!target.attr('id') ) {
          var targetId = target.attr('id');
          window.location.hash = targetId;
        }

        // Optional callback
        if ( typeof callback !== "undefined" ) {
          callback();
        }
      });

    return false;
  }
  // Jump to target position
  else {
    $window.scrollTop( targetPos );
  }
};
