//------------------------------------------------------------------------
// Modal windows (for modal toggle buttons, see modal-toggles.js)
//
// Note: Progressively enhanced, works with pure CSS thanks to the `:target` pseudo selector
//
// Inspired by https://www.smashingmagazine.com/2016/09/building-social-a-case-study-on-progressive-enhancement/#challenge-4-pure-css-modal-box-form-enhanced-with-javascript
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import events from './events';
import key from './keycodes';
import ua from './user-agent';
import scrolling from './freeze-scroll';

var Modal = Backbone.View.extend({
  events: {
    // Events defined in modalEvents()
  },

  initialize: function() {
    var self = this;
    // Use variable to store state instead of creating a model
    this.isOpen = false;
    this.$prevFocused = false;
    this.$window = $(window);
    this.$body = $('body');
    this.$document = $('html').add( this.$body );
    this.$video = false;
    this.videoIsReady = false;

    // Find close button
    this.$close = this.$el.find('.Modal-close');

    // Get modal name from data-modal attribute
    this.name = this.$el.data('modal') || false;

    // If no name, exit
    if ( !this.name ) {
      console.warn('Modal window has no “data-modal” value', this.$el);
      return false;
    }

    // Find toggles (can have multiple toggles for one modal)
    this.$toggles = $('[data-modal-toggle="' + this.name + '"]');

    // Get toggle text, use first if multiple
    this.toggleText = $.trim(this.$toggles.first().text());

    // Modals must be labeled, use toggle text if not defined
    this.ariaLabel = !!this.$el.attr('aria-label') ? this.$el.attr('aria-label') : this.toggleText;

    // Setup modal window
    this.setup();

    // Check if modal was opened on load (from hash in URL)
    if ( window.location.hash.length && window.location.hash.substring(1) == this.name ) {
      this.open();
    }
  },

  setup: function() {
    // Add ARIA attributes to modal content
    this.$el.attr({
      'aria-expanded': 'false',
      'aria-hidden': 'true',
      'aria-label': this.ariaLabel,
      'role': 'dialog',
      'tabindex': '-1'// disable tabbing until open
    }).addClass('js-init');

    this.$close.attr('role', 'button');

    // Bind modal events
    this.modalEvents();
  },

  modalEvents: function() {
    var self = this;

    // Close button event
    this.$close.on('click', function(evt) {
      self.close(evt);
    });

    // Hide if click outside of modal
    this.$body.on('click.modal', function(evt) {
      var $target = $(evt.target);
      if ( self.isOpen && !$target.closest('.About-nav').length && !$target.closest('.Modal-content').length && !$target.closest('[data-modal-toggle]').length ) {
        self.close(evt);
      }
    });

    // The keyup event isn't triggered when tabbing from the last focusable element to the browser's GUI, so use the keydown event instead
    events.on('keydown', function(evt) {

      // Do nothing if modal is closed
      if ( self.isOpen ) {

        // Close with escape key
        if ( evt.which === key.ESCAPE ) {
          self.close(evt);
        }

        // Listen for tab key when modal is open to manage focus
        if ( evt.which === key.TAB ) {

          // Find currently focused element
          var $focused = $(':focus');

          // Update tabbables
          self.$tabbables = self.$el.find('a, button, input, textarea, select').not('[tabindex="-1"]').filter(':visible');
          // console.log(self.$tabbables);

          // Get index of focused element
          var index = self.$tabbables.index( $focused );

          // If item outside of modal is focused, or last item in modal, focus the first item in modal
          if ( index === -1 || (!evt.shiftKey && index === self.$tabbables.length - 1) ) {
            // Prevent default since we're manually focusing the first element
            evt.preventDefault();
            self.$tabbables.eq(0).focus();
          }

          // If tabbing backwards and first element is focused, go to last element
          else if ( evt.shiftKey && index <= 0 ) {
            // Prevent default since we're manually focusing the last element
            evt.preventDefault();
            self.$tabbables.eq(self.$tabbables.length - 1).focus();
          }
        }
      }
    });

    // Listen for toggle event
    events.on('modal-toggle', function(evt, name) {
      if ( name === self.name ) {
        self.toggle(evt);
      }
    });

  },

  // Expand expandable
  open: function() {
    // Save currently focused element to focus on close
    this.$prevFocused = $(':focus');

    // Disable scrolling
    scrolling.disable();

    // Update modal ARIA attributes, show and focus
    this.$el.attr({
      'aria-expanded': 'true',
      'aria-hidden': 'false',
      'tabindex': ''
    }).addClass('is-active').focus();

    // Update toggles
    this.$toggles.attr({
      'aria-pressed': 'true',
      'aria-expanded': 'true'
    });

    // Update URL hash so users can link directly to the modal window content
    // Use history.replaceState() to prevent adding a new history entry
    // Note: If replaceState isn’t supported, modal-toggles.js won’t prevent the
    // default click event, causing the hash to update and creating a new history entry.
    if ( history.replaceState ) {
      history.replaceState(null, '', '#' + this.name);
    }

    // Update state
    this.isOpen = true;
  },

  // Collapse expandable
  close: function(evt) {
    var self = this;

    // Prevent default so we don’t see toggle button’s ID in the hash
    evt.preventDefault();

    // Enable scrolling
    scrolling.enable();

    // Update nav ARIA attributes, hide
    this.$el.attr({
      'aria-expanded': 'false',
      'aria-hidden': 'true',
      'tabindex': '-1'
    }).removeClass('is-active').removeAttr('tabindex');

    // Update toggles
    this.$toggles.attr({
      'aria-pressed': 'false',
      'aria-expanded': 'false'
    });

    // Get previously focused element
    var $focusEl = this.$prevFocused;

    // If no previously focused element, use modal toggle
    if ( !this.$prevFocused.length ) {
      $focusEl = this.$toggles.first();
    }

    // Focus element
    $focusEl.attr('tabindex', '-1').on('blur focusout', function() {
      $(this).removeAttr('tabindex');
    }).focus();

    // Clear hash using replaceState() to prevent adding a new history entry
    if ( history.replaceState ) {
      history.replaceState(null, '', window.location.pathname);
    }

    // Trigger close event, used by slidehsow to reset
    events.trigger('close-modal', this.name);

    // Update state
    this.isOpen = false;
  },

  // Toggle expandable
  toggle: function(evt) {
    if ( this.isOpen ) {
      this.close(evt);
    }
    else {
      this.open();
    }
  }
});

// Initialize
$('[data-modal]').each(function() {
  var modal = new Modal({ el: this });
});

// Return constructor so it can be used by other modules
export default Modal;
