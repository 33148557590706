//------------------------------------------------------------------------
// Poll functionality
//
// Designed for yes/no responses
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';

var Poll = Backbone.View.extend({
  events: {
    'submit': 'submitHandler'
  },

  initialize: function() {
    this.pollID = this.$el.data('poll');
    this.$submitButton = this.$el.find('[data-validate="submit"]');
    this.$results = $('[data-poll-results="' + this.pollID + '"]');
    this.$resultsData = this.$results.find('.Poll-results-data');
    this.$yesPercentage = this.$results.find('[data-poll-results-yes]');
    this.$noPercentage = this.$results.find('[data-poll-results-no]');
    this.$totalCount = this.$results.find('[data-poll-results-total]');
    this.$yesResource = this.$results.find('.Poll-results-resource--yes');
    this.$noResource = this.$results.find('.Poll-results-resource--no');
    this.$pollQuestionID = this.$("#poll-question-id");
  },

  showResults: function() {
    this.$el.attr('aria-hidden', 'true');
    this.$results.attr('aria-hidden', 'false');
  },

  submitHandler: function(evt) {
    var self = this;

    // Prevent form from submitting since we don’t want to force a page refresh
    evt.preventDefault();

    // Check if form is valid
    if ( !this.$el.hasClass('is-invalid') ) {
      // Get radio value
      var pollResponse = parseInt(this.$el.find('[type="radio"]:checked').val(), 10);
      var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');

      var data = {
        "poll_answer": {
          "answer": pollResponse,
          "poll_question_id": this.$pollQuestionID.val(),
        }
      };

      $.ajax({
        type: "POST",
        url: "/poll",
        data: data,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("X-CSRF-Token", AUTH_TOKEN);
        },
        success: function(data) {
          // Update values
          self.$yesPercentage.text(data.yes);
          self.$noPercentage.text(data.no);
          self.$totalCount.text(data.participants);
        },
        error: function() {
          console.error('Unable to post poll data to “/poll”', data);
          // Hide data
          self.$resultsData.hide();
        }
      });

      // Unhide appropriate resource
      if ( pollResponse === 0 ) {
        this.$noResource.addClass('is-active');
      }
      else {
        this.$yesResource.addClass('is-active');
      }

      this.showResults();
    }
  }
});

// Initialize
$('[data-poll]').each(function() {
  var poll = new Poll({ el: this });
});
