'use strict';
import $ from 'jquery';
import Backbone from 'backbone';

// jQuery Easing v1.3 - http://gsgd.co.uk/sandbox/jquery/easing/
$.extend( $.easing, {
  easeOutQuad: function (x, t, b, c, d) {
    return -c *(t/=d)*(t-2) + b;
  }
});

var MobileHeaderView = Backbone.View.extend({

  initialize: function() {
    var self = this;
    var isOpen = false;

    this.$nav = this.$(".MobileNavigation");
    this.$navToggle = this.$(".MobileHeader-navToggle");
    this.$navOverlay = this.$(".MobileHeader-overlay");

    this.navOverlayActiveClass = "-active";
    this.navActiveClass = "-active";
    this.toggleActiveClass = "-active";
    this.itemActiveClass = "-active";

    this.navToggleText = this.$navToggle.text();
    this.navCloseText = "Close";
  },

  events: {
    "click .MobileHeader-navToggle": "toggleMainMenu",
    "click .MobileNavigation-item.-withSubmenu .MobileNavigation-link": "toggleSubMenu",
  },

  toggleMainMenu: function(event) {
    event.preventDefault();
    if (this.isOpen) {
      this.collapseMainMenu();
    } else {
      this.expandMainMenu();
    }
  },

  expandMainMenu: function() {
    this.$navToggle.addClass(this.toggleActiveClass);
    this.$navToggle.text(this.navCloseText);
    this.$nav.addClass(this.navActiveClass);
    this.$navOverlay.addClass(this.navOverlayActiveClass);
    this.isOpen = true;
  },

  collapseMainMenu: function() {
    this.$navToggle.removeClass(this.toggleActiveClass);
    this.$navToggle.text(this.navToggleText);
    this.$nav.removeClass(this.navActiveClass);
    this.closeSubMenus();
    this.$navOverlay.removeClass("-active");
    this.isOpen = false;
  },

  closeSubMenus: function(event) {
    $(".MobileNavigation-item").removeClass(this.itemActiveClass);
    $(".MobileNavigation-subMenu").slideUp({
      duration: 200,
      easing: "easeOutQuad"
    });
  },

  toggleSubMenu: function(event) {
    event.preventDefault();

    var $targetItem = $(event.currentTarget).closest(".MobileNavigation-item");
    var $targetMenu = $targetItem.find(".MobileNavigation-subMenu");

    if ($targetItem.hasClass(this.itemActiveClass)) {
      this.closeSubMenus();
    } else {
      this.closeSubMenus();

      // open target item
      $targetItem.addClass(this.itemActiveClass);
      $targetMenu.slideDown({
        duration: 200,
        easing: "easeOutQuad"
      });
    }
  }
});

$(".MobileHeader").each( function() {
  var Header = new MobileHeaderView({ el: this });
});
