//------------------------------------------------------------------------
// User agent string functions
//
// Note: UA strings aren’t always 100% accurrate and shouldn’t be relied
//       upon for critical functionality.
//------------------------------------------------------------------------
"use strict";

export default {
  ua: window.navigator.userAgent,

  // Detect Android, returns version as float number if true
  // http://www.ainixon.me/how-to-detect-android-version-using-js/
  isAndroid: function() {
    var matches = this.ua.match(/Android\s([0-9\.]*)/);
    return matches ? parseFloat(matches[1], 10) : false;
  },

  // Detect IE, returns version number as integer if true
  // https://codepen.io/gapcode/pen/vEJNZN/?editors=0010
  isIE: function() {
    var msie = this.ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older
      return parseInt(this.ua.substring(msie + 5, this.ua.indexOf('.', msie)), 10);
    }

    var trident = this.ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11
      var rv = this.ua.indexOf('rv:');
      return parseInt(this.ua.substring(rv + 3, this.ua.indexOf('.', rv)), 10);
    }

    var edge = this.ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+)
      return parseInt(this.ua.substring(edge + 5, this.ua.indexOf('.', edge)), 10);
    }

    // not IE
    return false;
  },

  // Detect iOS, returns version number as float if true
  // Test page https://regex101.com/r/nG7lH4/1
  isIOS: function() {
    var matches = this.ua.match(/((\d+_?){2,3})\slike\sMac\sOS\sX/);
    return matches ? parseFloat(matches[1].replace('_','.').replace('_',''), 10) : false;
  },

  // Detect Samsung Internet browser, boolean
  isSamsung: function() {
    return /SamsungBrowser/.test(this.ua);
  }
};
