//------------------------------------------------------------------------
// Custom select helper
//
// Wraps all selects in a div so we can style them better, since selects
// don’t support :before/:after pseudo elements.
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';

$(".js-custom-select").each(function() {
  $(this).removeClass('js-custom-select').wrap('<div class="js-custom-select"></div>');
});
