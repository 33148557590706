//------------------------------------------------------------------------
// Video promo
//------------------------------------------------------------------------
'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
import events from './events';

var VideoPromo = Backbone.View.extend({
  events: {
    'click .VideoPromo-play': 'play'
  },

  initialize: function() {
    this.$video = this.$el.find('[data-youtube]');
    this.videoId = this.$video.data('youtube');
    this.$content = this.$el.find('.VideoPromo-wrap');
    this.$play = this.$el.find('.VideoPromo-play');

    // Once JS runs, link will operate like a button so we need to update its role
    this.$play.attr('role', 'button');
  },

  play: function(evt) {
    // Dont’t follow link
    evt.preventDefault();

    // Load the YouTube video on demand
    events.trigger('load-video', this.videoId, true);

    // Hide content
    this.$content.addClass('is-hidden');

    // Show video
    this.$video.addClass('is-active');
  }
});

// Initialize
$('.VideoPromo').each(function() {
  var videoPromo = new VideoPromo({ el: this });
});
